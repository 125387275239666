const Path = {
  DASHBOARD: '/dashbord',
  LOGIN: '/',
  SIGNUP: '/signup',
  MEMBER_SCREEN: '/member',
  FINANCIAL_YEAR_SCREEN: '/financial_year',
  SURNAME_SCREEN: '/surname',
  LAVAJAM_SCREEN: '/lavajam',
  TRANSACTION_SCREEN: '/transaction',
}

export default Path
