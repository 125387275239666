import { lazy } from 'react'
import Path from '../shared/constant/paths'
import {
  SmileOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  TrademarkCircleOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons'
import { ROLE } from '../services/constants/application'
const { ADMIN, COLLECTOR } = ROLE

//Import All Screen Here
const LoginScreen = lazy(() => import('../components/auth/login.screen'))
const MemberScreen = lazy(() =>
  import('../components/master/member/member.screen')
)
const FYScreen = lazy(() =>
  import('../components/master/financial_year/financial_year.screen')
)
const SurnameScreen = lazy(() =>
  import('../components/master/surname/surname.screen')
)
const LavajamScreen = lazy(() =>
  import('../components/finance/lavajam/lavajam.screen')
)
const TransactionScreen = lazy(() =>
  import('../components/finance/transactions/transaction.screen')
)


const AuthRoute = [
  {
    key: 'login',
    path: Path.LOGIN,
    ispublic: true,
    element: LoginScreen,
    roles: [ADMIN, COLLECTOR],
  },
]

export const MasterRoute = [
  {
    key: 'surname',
    label: 'Surname',
    path: Path.SURNAME_SCREEN,
    element: SurnameScreen,
    icon: <CalendarOutlined />,
    roles: [ADMIN],
  },
  {
    key: 'member',
    label: 'Member',
    path: Path.MEMBER_SCREEN,
    element: MemberScreen,
    icon: <SmileOutlined />,
    roles: [ADMIN],
  },
  {
    key: 'fy',
    label: 'Financial Year',
    path: Path.FINANCIAL_YEAR_SCREEN,
    element: FYScreen,
    icon: <ScheduleOutlined />,
    roles: [ADMIN],
  },
]

export const FinanceRoute = [
  {
    key: 'fn_lavajam',
    label: 'Lavajam & Donation',
    path: Path.LAVAJAM_SCREEN,
    element: LavajamScreen,
    icon: <TrademarkCircleOutlined />,
    roles: [ADMIN, COLLECTOR],
  },
  {
    key: 'fn_transaction',
    label: 'Transaction',
    path: Path.TRANSACTION_SCREEN,
    element: TransactionScreen,
    icon: <MoneyCollectOutlined />,
    roles: [ADMIN, COLLECTOR],
  },
]

const ScreenRoute = [...AuthRoute, ...MasterRoute, ...FinanceRoute]

export default ScreenRoute
