import appActionTypes from '../../constants/types'

export const initialState = {
  data: [],
  isLoading: false,
  message: null,
  errorMessage: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.transaction.get.requested:
    case appActionTypes.transaction.create.requested:
    case appActionTypes.transaction.delete.requested:
    case appActionTypes.transaction.edit.requested:
      return {
        ...state,
        isLoading: true,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.transaction.get.success:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.transaction.create.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      _newlist.push(action.data)
      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.transaction.delete.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      var index = _newlist.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _newlist.splice(index, 1)

      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }

    case appActionTypes.transaction.edit.success:
      var _newlist = JSON.parse(JSON.stringify(state.data))
      var index = _newlist.findIndex((obj) => obj._id === action.data?._id)
      if (index !== -1) _newlist[index] = action.data

      return {
        ...state,
        data: _newlist,
        isLoading: false,
        message: action.message,
        errorMessage: null,
      }
    case appActionTypes.transaction.get.fail:
    case appActionTypes.transaction.create.fail:
    case appActionTypes.transaction.delete.fail:
    case appActionTypes.transaction.edit.fail:
      return {
        ...state,
        isLoading: false,
        message: null,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
