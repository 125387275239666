import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function WithRouter(Child) {
    function InnerComponent(props) {
        const location = useLocation();
        const history = useNavigate();
        const parameters = useParams();
        
        return(
            <Child 
                {...props}
                location={location}
                history={history}
                parameters={parameters}
            />
        )
    }

    return InnerComponent
}

export default WithRouter