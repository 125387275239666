export default {
  auth: {
    register: {
      requested: 'AUTH_REGISTER_REQUESTED',
      success: 'AUTH_REGISTER_SUCCESS',
      fail: 'AUTH_REGISTER_FAIL',
    },
    login: {
      requested: 'AUTH_LOGIN_REQUESTED',
      success: 'AUTH_LOGIN_SUCCESS',
      fail: 'AUTH_LOGIN_FAIL',
    },
  },
  user: {
    get: {
      requested: 'USER_GET_REQUESTED',
      success: 'USER_GET_SUCCESS',
      fail: 'USER_GET_FAIL',
    },
    create: {
      requested: 'USER_CREATE_REQUESTED',
      success: 'USER_CREATE_SUCCESS',
      fail: 'USER_CREATE_FAIL',
    },
    edit: {
      requested: 'USER_CREATE_REQUESTED',
      success: 'USER_CREATE_SUCCESS',
      fail: 'USER_CREATE_FAIL',
    },
  },
  member: {
    get: {
      requested: 'MEMBER_GET_REQUESTED',
      success: 'MEMBER_GET_SUCCESS',
      fail: 'MEMBER_GET_FAIL',
    },
    create: {
      requested: 'MEMBER_CREATE_REQUESTED',
      success: 'MEMBER_CREATE_SUCCESS',
      fail: 'MEMBER_CREATE_FAIL',
    },
    edit: {
      requested: 'MEMBER_EDIT_REQUESTED',
      success: 'MEMBER_EDIT_SUCCESS',
      fail: 'MEMBER_EDIT_FAIL',
    },
    delete: {
      requested: 'MEMBER_DELETE_REQUESTED',
      success: 'MEMBER_DELETE_SUCCESS',
      fail: 'MEMBER_DELETE_FAIL',
    },
  },
  financial_year: {
    get: {
      requested: 'FY_GET_REQUESTED',
      success: 'FY_GET_SUCCESS',
      fail: 'FY_GET_FAIL',
    },
    create: {
      requested: 'FY_CREATE_REQUESTED',
      success: 'FY_CREATE_SUCCESS',
      fail: 'FY_CREATE_FAIL',
    },
    edit: {
      requested: 'FY_EDIT_REQUESTED',
      success: 'FY_EDIT_SUCCESS',
      fail: 'FY_EDIT_FAIL',
    },
    delete: {
      requested: 'FY_DELETE_REQUESTED',
      success: 'FY_DELETE_SUCCESS',
      fail: 'FY_DELETE_FAIL',
    },
    close: {
      requested: 'FY_CLOSE_REQUESTED',
      success: 'FY_CLOSE_SUCCESS',
      fail: 'FY_CLOSE_FAIL',
    }
  },
  surname: {
    get: {
      requested: 'SURNAME_GET_REQUESTED',
      success: 'SURNAME_GET_SUCCESS',
      fail: 'SURNAME_GET_FAIL',
    },
    create: {
      requested: 'SURNAME_CREATE_REQUESTED',
      success: 'SURNAME_CREATE_SUCCESS',
      fail: 'SURNAME_CREATE_FAIL',
    },
    edit: {
      requested: 'SURNAME_EDIT_REQUESTED',
      success: 'SURNAME_EDIT_SUCCESS',
      fail: 'SURNAME_EDIT_FAIL',
    },
    delete: {
      requested: 'SURNAME_DELETE_REQUESTED',
      success: 'SURNAME_DELETE_SUCCESS',
      fail: 'SURNAME_DELETE_FAIL',
    },
  },
  lavajam: {
    get: {
      requested: 'LAVAJAM_GET_REQUESTED',
      success: 'LAVAJAM_GET_SUCCESS',
      fail: 'LAVAJAM_GET_FAIL',
    },
    create: {
      requested: 'LAVAJAM_CREATE_REQUESTED',
      success: 'LAVAJAM_CREATE_SUCCESS',
      fail: 'LAVAJAM_CREATE_FAIL',
    },
    edit: {
      requested: 'LAVAJAM_EDIT_REQUESTED',
      success: 'LAVAJAM_EDIT_SUCCESS',
      fail: 'LAVAJAM_EDIT_FAIL',
    },
    delete: {
      requested: 'LAVAJAM_DELETE_REQUESTED',
      success: 'LAVAJAM_DELETE_SUCCESS',
      fail: 'LAVAJAM_DELETE_FAIL',
    },
  },
  transaction: {
    get: {
      requested: 'TRANSACTION_GET_REQUESTED',
      success: 'TRANSACTION_GET_SUCCESS',
      fail: 'TRANSACTION_GET_FAIL',
    },
    create: {
      requested: 'TRANSACTION_CREATE_REQUESTED',
      success: 'TRANSACTION_CREATE_SUCCESS',
      fail: 'TRANSACTION_CREATE_FAIL',
    },
    edit: {
      requested: 'TRANSACTION_EDIT_REQUESTED',
      success: 'TRANSACTION_EDIT_SUCCESS',
      fail: 'TRANSACTION_EDIT_FAIL',
    },
    delete: {
      requested: 'TRANSACTION_DELETE_REQUESTED',
      success: 'TRANSACTION_DELETE_SUCCESS',
      fail: 'TRANSACTION_DELETE_FAIL',
    },
  },
}
