import { combineReducers } from 'redux'

// Import All State
import userState from './auth/userReducer'
import authState from './auth/authReducer'
import memberState from './master/memberReducer'
import fyState from './master/financial_yearReducer'
import surnameState from './master/surnameReducer'
import lavajamState from './finance/lavajamReducer'
import transactionState from './finance/transactionReducer'

export default combineReducers(
  Object.assign({
    userState,
    authState,
    memberState,
    fyState,
    surnameState,
    lavajamState,
    transactionState,
  })
)
